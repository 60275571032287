import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-688ce87e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "bsj_map_wrapper"
};
const _hoisted_2 = ["id"];
const _hoisted_3 = {
  ref: "refInfo",
  class: "window_outer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    id: _ctx.mapElId,
    ref: "mapEl",
    class: _normalizeClass(['map_outer', _ctx.mapElId + 'Cls', _ctx.panoramaOnoff ? 'w_50' : ''])
  }, null, 10, _hoisted_2), _withDirectives(_createElementVNode("div", {
    id: "panorama",
    style: {
      "position": "absolute",
      "right": "0",
      "top": "0",
      "width": "50%",
      "height": "100%"
    },
    ref: "panaoramaEl",
    class: _normalizeClass(['map_outer', _ctx.mapElId + 'Cls'])
  }, null, 2), [[_vShow, _ctx.panoramaOnoff]]), _withDirectives(_createElementVNode("div", null, [_createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "window", {}, undefined, true), _ctx.infoConfig.showClose ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "close_icon",
    title: "关闭",
    onClick: _cache[0] || (_cache[0] = _withModifiers(
    //@ts-ignore
    (...args) => _ctx.closeInfoWindow && _ctx.closeInfoWindow(...args), ["stop"]))
  }, [_createVNode(_component_Icon, {
    name: "Close"
  })])) : _createCommentVNode("", true)], 512)], 512), [[_vShow, false]])]);
}