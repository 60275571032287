import BsjMap from 'bsj-map';

// https://mapopen-pub-jsapi.bj.bcebos.com/jsapi/reference/jsapi_webgl_1_0.html
// http://120.25.194.144:9001/#/start/basicClass

const ak = 'mVoHblLKSQjIjRpqtEZjzE4jIgUTQdKC';
if(!ak){
  alert('请填写百度地图ak');
}
const bdToken = `https://api.map.baidu.com/getscript?v=3.0&ak=${ak}&s=1`;


const Map = new BsjMap('bmap', bdToken);

export function initMap(options) {
  return Map.init({
    enableMapClick: false,
    isDraw: true,
    isMarkerClusterer: true,
    ...(options || {})
  });
}

