


interface IDraw {
  removeCover: () => void,
}

interface IPolygonResult extends IDraw {
  path: [];
}
interface ICircleResult extends IDraw {
  path: [];
  center: [];
  radius: number;
}

interface IRectangleResult extends IDraw {
  path: [];
  bounds: any[];
}


export default function (mapObj) {

  function getMap() {
    if (mapObj instanceof Function) {
      return mapObj();
    }
    return mapObj;
  }

  // 添加位置点
  function addMarker(point, options = {}) {
    try {
      const map = getMap();
      return map.addMarker(point, options);
    } catch (err) {
      console.error(err);
    }
  }

  // 位置点
  function addPosMarker(point, options = { acc: 0 }) {
    try {
      const map = getMap();
      const { acc, ...opts } = options;
      return map.addMarker(point, {
        offset: map.setSize([0, -25]),
        icon:
          map.setIcon(
            require(`@/assets/images/map_icon_3.png`),
            {
              size: map.setSize([40, 49]),
              // imageOffset: map.setPoint([20, 10])
            }
          ),
        ...opts,
      }
      );
    } catch (err) {
      console.error(err);
    }
  };

  // 开始位置点
  function addStartMarker(point, options) {
    try {
      const map = getMap();
      return map.addMarker(point, {
        offset: map.setSize([0, -25]),
        icon:
          map.setIcon(
            require('@/assets/images/pos_start.png'),
            {
              size: map.setSize([40, 49]),
              // imageOffset: 
            }
          ),
        ...options,
      });
    } catch (err) {
      console.error(err);
    }
  };

  // 结束位置点
  function addEndMarker(point, options) {
    try {
      const map = getMap();
      return map.addMarker(point, {
        offset: map.setSize([0, -25]),
        icon:
          map.setIcon(
            require('@/assets/images/pos_end.png'),
            {
              size: map.setSize([40, 49]),
              // imageOffset: [20, 10]
            }
          ),
        ...options,
      });
    } catch (err) {
      console.error(err);
    }
  };


  return {
    addMarker,
    addPosMarker,
    addStartMarker,
    addEndMarker,
  };

}