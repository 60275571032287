


interface IDraw {
  removeCover: () => void,
}

interface IPolygonResult extends IDraw {
  path: [];
}
interface ICircleResult extends IDraw {
  path: [];
  center: [];
  radius: number;
}

interface IRectangleResult extends IDraw {
  path: [];
  bounds: any[];
}


export default function (mapObj) {

  function getMap() {
    if (mapObj instanceof Function) {
      return mapObj();
    }
    return mapObj;
  }

  // 绘制多边形
  function drawPolygon(drawOptions = {}): Promise<IPolygonResult> {

    let drawCover = null;
    const map = getMap();

    function removeCover() {
      map.removeCover(drawCover);
    }

    return new Promise((resolve, reject) => {
      try {
        const draw = map.drawPolygon
          (
            {
              strokeColor: '#666', // 折线颜色
              strokeWeight: 3, //    折线的宽度，以像素为单位
              strokeOpacity: 0.8, // 折线的透明度，取值范围0 - 1
              ...(drawOptions || {}),
            },
            (ev) => {
              drawCover = ev;
              const path = ev.getPath();
              map.closeDraw(draw);

              resolve(
                {
                  path,
                  removeCover
                },
              );
            });
      } catch (error) {
        reject(error);
      }
    });
  };

  // 绘制矩形
  function drawCircle(drawOptions = {}): Promise<ICircleResult> {

    let drawCover = null;
    const map = getMap();

    function removeCover() {
      map.removeCover(drawCover);
    }

    return new Promise((resolve, reject) => {
      try {
        const draw = map.drawCircle
          (
            {
              strokeColor: '#666', // 折线颜色
              strokeWeight: 3, //    折线的宽度，以像素为单位
              strokeOpacity: 0.8, // 折线的透明度，取值范围0 - 1
              ...(drawOptions || {}),
            },
            (ev) => {
              drawCover = ev;
              const center = ev.getCenter();
              const path = ev.getPath();
              const radius = ev.getRadius();
              map.closeDraw(draw);

              resolve(
                {
                  path,
                  center,
                  radius,
                  removeCover
                }

              );
            });
      } catch (error) {
        reject(error);
      }
    });
  };

  // 绘制圆形
  function drawRectangle(drawOptions = {}): Promise<IRectangleResult> {

    let drawCover = null;
    const map = getMap();

    function removeCover() {
      map.removeCover(drawCover);
    }

    return new Promise((resolve, reject) => {
      try {
        const draw = map.drawRectangle
          (
            {
              strokeColor: '#666', // 折线颜色
              strokeWeight: 3, //    折线的宽度，以像素为单位
              strokeOpacity: 0.8, // 折线的透明度，取值范围0 - 1
              ...(drawOptions || {}),
            },
            (ev) => {
              drawCover = ev;
              const { Te, be, Ne, ee } = ev.getBounds();

              const path = ev.getPath();
              const bounds = [[be, Te], [ee, Ne]];
              map.closeDraw(draw);

              resolve(
                {
                  path,
                  bounds,
                  removeCover
                }
              );
            });
      } catch (error) {
        reject(error);
      }
    });
  };

  return {
    drawPolygon,
    drawCircle,
    drawRectangle,
  };

}