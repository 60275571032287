import "core-js/modules/es.array.push.js";
import { defineComponent, unref, onMounted, nextTick, reactive, toRefs, watch, computed } from "vue";
import { initMap } from "./init";
import { isNullOrUnDef } from "@/utils/is";
import { defaultInfo } from "./props";
import { isSomePoint } from "@/utils/bdGPS";
import useMapDraw from "./hooks/useMapDraw";
import useMapMarker from "./hooks/useMapMarker";
import sleep from "@/utils/sleep";
import { randomString } from "@/utils/random";
import { bdEncrypt } from "@/utils/bdGPS";
// 自定义地图json
import CustomMapConfig from "./json/custom_map_config";
export default defineComponent({
  name: "BSJMAP",
  components: {},
  emits: ["update:infoVisible", "handleMarkerClick"],
  props: {
    // 是否开启地图自定义样式
    isCustomizeStyle: {
      type: Boolean,
      default: false
    },
    isSatellite: {
      type: Boolean,
      default: false
    },
    mapOptions: {
      type: Object,
      default: null
    },
    center: {
      type: Object,
      default() {
        return {
          lon: 116.404412,
          lat: 39.914714
        };
      }
    },
    zoom: {
      type: Number,
      default: 12,
      validator(val) {
        return val >= 3 && val <= 19;
      }
    },
    infoOptions: {
      type: Object,
      default() {
        return defaultInfo;
      }
    },
    infoWidth: {
      type: Number,
      default: 100
    },
    infoHeight: {
      type: Number,
      default: 100
    },
    minClusterSize: {
      type: Number,
      default: 25
    }
  },
  setup(props, {
    emit
  }) {
    // 地图实例
    let mapObj = null;
    // 地图窗口实例
    let infoInstance = null;
    // 地图窗口标点
    let infoMarker = null;
    // 显示窗口
    let infoVisible = false;
    // 聚合对象
    let mapClusterer = null;
    const refData = reactive({
      //全景容器
      panaoramaEl: null,
      panoramaMap: null,
      panoramaOnoff: false,
      // 地图容器
      mapElId: randomString(),
      mapEl: null,
      // 窗口
      refInfo: null,
      //全景
      panorama: null
    });
    const mapDrawEx = useMapDraw(getMap);
    const mapMarkerEx = useMapMarker(getMap);
    const centerProps = computed(() => props.center || {
      lon: 116.404412,
      lat: 39.914714
    });
    const infoConfig = computed(() => ({
      ...defaultInfo,
      ...(props.infoOptions || {})
    }));
    function getMap() {
      return mapObj;
    }
    function getInnerMap() {
      return mapObj.instance;
    }
    function getInfoWindow() {
      return infoInstance;
    }
    function isOpenWindow() {
      return infoVisible;
    }
    // 初始化地图
    async function init() {
      const center = unref(centerProps);
      mapObj = await initMap({
        el: refData.mapEl,
        center: [center.lon, center.lat],
        zoom: props.zoom,
        ...(props.mapOptions || {})
      });
      props.isSatellite && mapObj.instance.addControl(new BMap.MapTypeControl({
        mapTypes: [BMAP_NORMAL_MAP, BMAP_SATELLITE_MAP]
      }));
      // 启动自定义地图
      props.isCustomizeStyle && mapObj.instance.setMapStyleV2({
        styleJson: CustomMapConfig
      });
    }
    // 初始化地图窗口
    async function initInfo() {
      const map = getMap();
      const {
        offset,
        enableCloseOnClick
      } = unref(infoConfig);
      // 创建窗口
      infoInstance = map.InfoWindow({
        enableCloseOnClick,
        offset: map.setSize(offset),
        width: props.infoWidth,
        height: props.infoHeight,
        enableAutoPan: true
      }, refData.refInfo);
      const center = unref(centerProps);
      // 创建窗口标点
      infoMarker = mapObj.addMarker([center.lon, center.lat], {
        offset: mapObj.setSize([5, -15]),
        icon: mapObj.setIcon(require("@/assets/images/bg_opacity0.png"), {
          size: mapObj.setSize([12, 12])
        })
      });
      mapObj.setMap(infoMarker);
      infoMarker.setTop(false);
      infoMarker.hide();
    }
    async function initClusterer() {
      await sleep(300);
      let options = {
        fillStyle: "rgba(255, 50, 0, 1.0)",
        shadowColor: 'rgba(255, 255, 255, 1)',
        size: 20,
        minSize: 20,
        maxSize: 41,
        clusterRadius: 150,
        maxClusterZoom: 18,
        maxZoom: 19,
        minPoints: 3,
        extent: 400,
        label: {
          // 聚合文本样式
          show: true,
          fillStyle: "white"
        },
        gradient: {
          0: "rgb(255,0,0,0.7)",
          0.5: "rgb(255,0,0,0.7)",
          1.0: "rgb(255,0,0,0.7)"
        },
        draw: "cluster",
        methods: {
          click(point) {
            if (point && !point.children.length) {
              emit('handleMarkerClick', point.geometry.info);
              openInfoWindow({
                lon: point.geometry.coordinates[0],
                lat: point.geometry.coordinates[1]
              });
            }
          }
        }
      };
      mapClusterer = mapObj.setMarkerClusterer([], options, () => ``);
    }
    // 打开窗口
    function openInfoWindow(point) {
      try {
        if (!infoMarker.isVisible()) {
          infoMarker.show();
        }
        if (!isSomePoint(point, infoMarker.getPosition())) {
          // 不同位置点
          infoMarker.setPosition(mapObj.setPoint([point.lon, point.lat]));
        }
        // 未打开窗口, 则打开
        if (!infoVisible) {
          infoMarker.openInfoWindow(infoInstance);
        }
        panToPoint(point);
        infoVisible = infoInstance.isOpen();
      } catch (err) {
        console.error(err);
      }
    }
    // 关闭窗口
    function closeInfoWindow() {
      try {
        if (infoMarker) {
          infoMarker.closeInfoWindow();
          infoMarker.hide();
        }
        infoVisible = false;
      } catch (err) {
        console.error(err);
      }
    }
    // 添加聚合点
    async function addClusterer(markers) {
      try {
        if (markers.length === 0) return;
        let points = [];
        markers.map(item => {
          points.push({
            geometry: {
              type: "Point",
              coordinates: item.point,
              info: item.info
            },
            // 具体点的icon设置
            iconOptions: {
              url: require(`../../assets/images/map_icon_3.png`),
              width: 34,
              height: 39
            }
          });
        });
        mapClusterer && mapClusterer[1].set(points);
      } catch (err) {
        console.error(err);
      }
    }
    // 删除聚合
    function clearClusterer() {
      try {
        mapClusterer[0] && mapClusterer[1].set([]);
      } catch (err) {
        console.log(err);
      }
    }
    // 位置点移动到可视区
    const panToPoint = async (point, force = false) => {
      const innerMap = getInnerMap();
      if (mapObj && innerMap) {
        if (force || !mapObj.markerIsBounds([point.lon, point.lat])) {
          mapObj.setCenter([point.lon, point.lat]);
          await sleep(200);
          innerMap.panBy(-100, 200);
        }
      }
    };
    //开启街景模式
    const handlePanorama = point => {
      const center = unref(centerProps);
      refData.panoramaOnoff = true;
      nextTick(async () => {
        // if(!refData.panorama && !refData.panoramaMap){
        refData.panoramaMap = await initMap({
          el: refData.panaoramaEl,
          ...(props.mapOptions || {}),
          center: [center.lon, center.lat],
          zoom: 12
        });
        refData.panorama = new BMap.Panorama(refData.panaoramaEl, {
          albumsControl: true
        });
        refData.panorama.setPov({
          heading: -40,
          pitch: 6
        });
        // }
        const {
          lat,
          lon
        } = bdEncrypt([point.lon, point.lat]);
        refData.panorama.setPosition(new BMap.Point(lon, lat));
        const panoramaService = new BMap.PanoramaService();
        panoramaService.getPanoramaByLocation(new BMap.Point(lon, lat), data => {
          if (data == null) {
            // refData.panoramaMap && refData.panoramaMap.clearOverlays && refData.panoramaMap.clearOverlays();
            // refData.panoramaMap = null;
            // refData.panorama = null;
            document.getElementById("panorama").innerHTML = `<div style="height: 100%;width: 100%; background: rgb(226, 226, 226);  display: flex;align-items: center;justify-content: center;">暂无街景</div>`;
            return;
          }
        });
      });
    };
    //关闭街景模式
    const onClosePanorman = point => {
      refData.panoramaOnoff = false;
    };
    // 窗口大小变化
    watch(() => [props.infoWidth, props.infoHeight], ([width, height], [oldWidth, oldHeight]) => {
      if (isNullOrUnDef(infoInstance)) return;
      if (width === oldWidth && height === oldHeight) return;
      infoInstance.setWidth(width);
      infoInstance.setHeight(height);
    });
    onMounted(async () => {
      await sleep(300);
      await init();
      await sleep(500);
      await initInfo();
      setTimeout(async () => {
        await initClusterer();
      }, 2000);
    });
    return {
      ...toRefs(refData),
      ...mapDrawEx,
      ...mapMarkerEx,
      init,
      infoConfig,
      getMap,
      getInfoWindow,
      openInfoWindow,
      closeInfoWindow,
      isOpenWindow,
      panToPoint,
      addClusterer,
      clearClusterer,
      handlePanorama,
      onClosePanorman
    };
  }
});