export default [{
  "featureType": "background",
  "elementType": "geometry",
  "stylers": {
      "visibility": "on",
      "color": "#114398ff"
  }
}, {
  "featureType": "land",
  "elementType": "geometry",
  "stylers": {
      "color": "#114398ff"
  }
}, {
  "featureType": "water",
  "elementType": "geometry",
  "stylers": {
      "color": "#4a90e254"
  }
}, {
  "featureType": "road",
  "elementType": "geometry.fill",
  "stylers": {
      "color": "#417505b0"
  }
}, {
  "featureType": "road",
  "elementType": "geometry.stroke",
  "stylers": {
      "color": "#7ed32100"
  }
}, {
  "featureType": "road",
  "elementType": "labels.text.fill",
  "stylers": {
      "color": "#ffffffff"
  }
}, {
  "featureType": "road",
  "elementType": "labels.text.stroke",
  "stylers": {
      "color": "#00000000"
  }
}, {
  "featureType": "districtlabel",
  "elementType": "labels.text.fill",
  "stylers": {
      "color": "#ffffffff"
  }
}, {
  "featureType": "districtlabel",
  "elementType": "labels.text.stroke",
  "stylers": {
      "color": "#b8e98600"
  }
}, {
  "featureType": "poilabel",
  "elementType": "labels.text.fill",
  "stylers": {
      "color": "#ffffffff"
  }
}, {
  "featureType": "poilabel",
  "elementType": "labels.text.stroke",
  "stylers": {
      "color": "#00000000"
  }
}]